
import VueTypes from 'vue-types';
import breakpoints from '~/_tailwind/tailwind.config.breakpoints';

export default {
  inheritAttrs: false,
  props: {
    alt: VueTypes.string,
    sizes: VueTypes.object.def(breakpoints),
    renderPlaceholder: VueTypes.bool.def(true),
    lazy: VueTypes.bool.def(true),
    imgClasses: VueTypes.string,

    id: VueTypes.string,
    title: VueTypes.string,
    kind: VueTypes.string,
    src: VueTypes.string,
    srcset: VueTypes.string,
    mimeType: VueTypes.string,
    credits: VueTypes.string,
    width: VueTypes.number,
    height: VueTypes.number,
    focalPoint: VueTypes.arrayOf(VueTypes.number).def([0.5, 0.5]),
    imageTransforms: VueTypes.shape({
      placeholderImage: VueTypes.string,
      src: VueTypes.string,
      srcWebp: VueTypes.string,
      maxSrcsetWidth: VueTypes.number,
      srcset: VueTypes.string,
      srcsetWebp: VueTypes.string,
    }).loose,
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    sizesAttr() {
      return Object.keys(this.sizes)
        .map((breakpoint) =>
          breakpoint === 'xs'
            ? `${this.sizes[breakpoint]}px`
            : `(min-width: ${breakpoints[breakpoint]}px) ${this.sizes[breakpoint]}px`,
        )
        .reverse()
        .join(', ');
    },
    imgSrc() {
      return this.imageTransforms && this.imageTransforms.src.length
        ? this.imageTransforms.src
        : this.src || null;
    },
    imgSrcset() {
      return this.imageTransforms && this.imageTransforms.srcset.length
        ? this.imageTransforms.srcset
        : this.srcset || null;
    },
    hasTransforms() {
      return (
        typeof this.imageTransforms !== 'undefined' &&
        this.imageTransforms.srcset.length
      );
    },
    usePlaceholder() {
      return (
        this.renderPlaceholder &&
        this.imageTransforms &&
        this.imageTransforms.placeholderImage
      );
    },
    focalX() {
      return this.focalPoint[0] * 100;
    },
    focalY() {
      return this.focalPoint[1] * 100;
    },
    hasFocal() {
      return !(this.focalX === 50 && this.focalY === 50);
    },
    pictureStyle() {
      return {
        backgroundImage:
          !this.loaded && this.usePlaceholder
            ? `url(${this.imageTransforms.placeholderImage})`
            : null,
        backgroundPosition: this.hasFocal
          ? `${this.focalX}% ${this.focalY}%`
          : 'center',
      };
    },
    imgStyle() {
      return {
        objectPosition: this.hasFocal
          ? `${this.focalX}% ${this.focalY}%`
          : null,
      };
    },
    altText() {
      return this.alt || this.title || '';
    },
  },
};
