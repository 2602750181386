
import VueTypes from 'vue-types';

export default {
  inheritAttrs: false,
  props: {
    credits: VueTypes.string.def(''),
    align: VueTypes.oneOf(['left', 'right', 'left-right', 'right-left']).def(
      'left',
    ),
  },
};
