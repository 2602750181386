import { render, staticRenderFns } from "./VImage.vue?vue&type=template&id=6f2be2d6&scoped=true"
import script from "./VImage.vue?vue&type=script&lang=js"
export * from "./VImage.vue?vue&type=script&lang=js"
import style0 from "./VImage.vue?vue&type=style&index=0&id=6f2be2d6&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f2be2d6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VisualCredits: require('/workspace/app/components/media/VisualCredits.vue').default})
